import API from 'api/api';
import { normalize } from 'utils/normalizeContacts';
import { capitalizeFirstLetter } from 'utils';
import { isNumber } from 'lodash';

const duckName = 'mail/';

export const GET_EMAILS = duckName + 'GET_EMAILS';
export const ADD_EMAIL = duckName + 'ADD_EMAIL';
export const GET_FILTERED_EMAILS = duckName + 'GET_FILTERED_EMAILS';
export const SET_EMAILS_TO_ACTIVE_FOLDER = duckName + 'SET_EMAILS_TO_ACTIVE_FOLDER';

export const SET_UNREAD_COUNT = duckName + 'SET_UNREAD_COUNT';
export const SET_OFFSET = duckName + 'SET_OFFSET';

export const SET_ACTIVE_MAIL_CHAT = duckName +'SET_ACTIVE_MAIL_CHAT';
export const CLEAR_ACTIVE_MAIL_CHAT = duckName +'CLEAR_ACTIVE_MAIL_CHAT';
export const ADD_MESSAGE_TO_MAIL_CHAT = duckName +'ADD_MESSAGE_TO_MAIL_CHAT';
export const DELETE_MSG_FROM_MAIL_CHAT = duckName + 'DELETE_MSG_FROM_MAIL_CHAT';
export const SET_MESSAGE_DRAFT = duckName + 'SET_MESSAGE_DRAFT';
export const DELETE_MESSAGE_DRAFT = duckName + 'DELETE_MESSAGE_DRAFT';

export const DELETE_CONVERSATIONS_FROM_ALL_FOLDERS = duckName + 'DELETE_CONVERSATIONS_FROM_ALL_FOLDERS';
export const DELETE_CONVERSATIONS_FROM_FOLDER = duckName + 'DELETE_CONVERSATIONS_FROM_FOLDER';

export const MARK_MAILS_NOT_SPAM = duckName + 'MARK_MAILS_NOT_SPAM';
export const UNDELETE_MAILS = duckName + 'UNDELETE_MAILS';
export const SET_IS_MAIL_STARRED = duckName + 'SET_IS_MAIL_STARRED';
export const TOGGLE_IS_MAIL_SELECTED = duckName + 'TOGGLE_IS_MAIL_SELECTED';
export const CLEAR_SELECTED_MAILS = duckName + 'CLEAR_SELECTED_MAILS';

export const SET_DELAYED_ACTION = duckName + 'SET_DELAYED_ACTION';
export const UNSET_DELAYED_ACTION = duckName + 'UNSET_DELAYED_ACTION';
export const CLEAR_DELAYED_ACTIONS = duckName + 'CLEAR_DELAYED_ACTIONS';

export const SET_COMPOSE_PENDING = duckName + 'SET_COMPOSE_PENDING';
export const SET_COMPOSE_DRAFT_ID = duckName + 'SET_COMPOSE_DRAFT_ID';
export const SET_DRAFT_STATUS = duckName + 'SET_DRAFT_STATUS';

export const SET_ACTIVE_FOLDER = duckName + 'SET_ACTIVE_FOLDER';
export const CLEAR_ACTIVE_FOLDER = duckName + 'CLEAR_ACTIVE_FOLDER';
export const SET_MAIL_LIST_PENDING = duckName + 'SET_MAIL_LIST_PENDING';
export const SET_ACTIVE_SORT_FILTER = duckName + 'SET_ACTIVE_SORT_FILTER';
export const GET_AVAILABLE_MAILS_FOR_USER = duckName + 'GET_AVAILABLE_MAILS_FOR_USER';
export const CHANGE_DEFAULT_MAIL_FOR_USER = duckName + 'CHANGE_DEFAULT_MAIL_FOR_USER';
export const GET_USERS_AVATARS_FOR_MAIL = duckName + 'GET_USERS_AVATARS_FOR_MAIL';
export const CHANGE_DEFAULT_MODE_OF_OPERATION = duckName + 'CHANGE_DEFAULT_MODE_OF_OPERATION';
export const SET_ACTIVE_FOLDER_TAB_NAME = duckName + 'SET_ACTIVE_FOLDER_TAB_NAME';
export const UPDATE_NOTES = 'UPDATE_NOTES';

export const UPDATE_SEARCH = duckName + 'UPDATE_SEARCH';

export const MAIL_LIST_LIMIT = 50;

export const LIST_TYPES = {
  inbox: 'inbox',
  archive: 'archive',
  sent: 'sent',
  drafts: 'drafts',
  spam: 'spam',
  trash: 'trash',
  search: 'search',
};

export const COMPOSE_PENDING_TYPES = {
  sendMsg: 'sendMsg',
  attach: 'attach',
  delDraft: 'delDraft',
};

export const DRAFT_STATUSES = {
  saved: 'saved',
  pending: 'pending',
  notSaved: 'not saved',
  savedNotice: 'saved notice',
};

export const MAIL_LIST_SORT_OPTIONS = [
  { label: 'Most Recent', value: 'DESC' },
  { label: 'Oldest', value: 'ASC' },
  { label: 'With attachment', value: 'attachment' },
  { label: 'Members', value: 'member' },
];

export const MAIL_LIST_FOLDER_TABS = {
  [LIST_TYPES.inbox]: [
    { operatorName: 'All Mails', mode: null },
    { operatorName: 'Unread', mode: 'unread' },
    { operatorName: 'Marked', mode: 'starred' },
  ],
};

export const getEmails = (config) => (dispatch) => {
  dispatch(setMailListPending(true));

  return API.getEmailsNew(config)
    .then(({ data }) => {
      dispatch({
        type: GET_EMAILS,
        payload: {
          mails: normalize(data.mails, 'conversation_id'),
          totalCount: data.count,
          type: config.type,
        }
      })
    })
    .catch(console.error);
}

export const setEmailsToActiveFolder = (mailsFolderData) => ({
  type: SET_EMAILS_TO_ACTIVE_FOLDER,
  payload: mailsFolderData,
});

export const addEmail = (mailData) => (dispatch) => {
  dispatch({
    type: ADD_EMAIL,
    payload: mailData
  });
}

export const setUnreadEmailsCount = (payload) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_COUNT,
    payload,
  })
}

export const setIsMailStarred = ({ folder, conversationId, isStarred, activeMailTab }) => (dispatch) => {
  return API.setIsMailStarred({ conversationId, isStarred })
    .then(() => {
      dispatch({
        type: SET_IS_MAIL_STARRED,
        payload: { folder, conversationId, activeMailTab }
      });
    })
    .catch(console.error);
}

export const setEmailsOffset = (offset) => (dispatch) => {
  dispatch({
    type: SET_OFFSET,
    payload: offset
  });
}

export const setActiveMailChat = (id, messages) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_MAIL_CHAT,
    payload: { id, messages },
  });
}

export const clearActiveMailChat = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_MAIL_CHAT,
  });
}

export const addMessageToMailChat = (message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE_TO_MAIL_CHAT,
    payload: message,
  });
}

export const deleteMsgFromMailChat = (msgId) => ({
  type: DELETE_MSG_FROM_MAIL_CHAT,
  payload: msgId,
});

export const setMessageDraft = ({ msgId, draft }) => ({
  type: SET_MESSAGE_DRAFT,
  payload: { msgId, draft },
});

export const deleteMessageDraft = (draftId) => ({
  type: DELETE_MESSAGE_DRAFT,
  payload: draftId,
});

export const deleteMessageDraftRequest = (draftId) => (dispatch) => {
  return API.deleteMailDraft(draftId)
    .then(() => {
      dispatch(deleteMessageDraft(draftId));
    });
}

export const deleteConversationsFromAllFolders = (conversationsIds) => ({
  type: DELETE_CONVERSATIONS_FROM_ALL_FOLDERS,
  payload: { conversationsIds },
});

export const deleteConversationsFromFolder = (payload) => ({
  type: DELETE_CONVERSATIONS_FROM_FOLDER,
  payload,
});

export const deleteMailsToTrash = (config) => (dispatch) => {
  // Config contains next params: offset, sortBy, folder, mode, limit, conversationsIds
  // conversationsIds are selected emails that will be moved to the trash
  // offset, sortBy, folder, mode, limit are sent to the backend to determine which emails to display on the current page
  const { conversationsIds, folder } = config;

  return API.deleteMailsToTrash(config)
    .then(({ data }) => {
      const { offset, mails, count } = data;

      if (config.msgId) {
        dispatch(deleteMsgFromMailChat(config.msgId));
      } else if (conversationsIds.length) {
        dispatch(setDelayedAction({
          delay: 30,
          type: 'delete',
          delayedActionText: 'Moved to Trash!',
          cancelRequestParams: { conversationsIds },
          actionId: `delete-${conversationsIds[0]}`,
        }));

        dispatch(clearSelectedMails());

        dispatch(setEmailsToActiveFolder({ offset, mails, totalCount: count }));
      }
    })
    .catch(console.error);
};

export const deleteMailsPermanently = (config) => (dispatch) => {
  // Config contains next params: offset, sortBy, folder, mode, limit, conversationsIds
  // conversationsIds are selected emails that will be deleted permanently
  // offset, sortBy, folder, mode, limit are sent to the backend to determine which emails to display on the current page
  const { conversationsIds } = config;

  return API.deleteMailsPermanently(config)
    .then(({ data }) => {
      const { offset, mails, count } = data;

      if (config.msgId) {
        dispatch(deleteMsgFromMailChat(config.msgId));
      } else if (conversationsIds.length) {
        dispatch(setDelayedAction({
          delay: 30,
          type: 'delete',
          delayedActionText: `Message(s) Deleted!`,
          cancelRequestParams: { conversationsIds },
          actionId: `permanently-delete-${conversationsIds[0]}`,
        }));

        dispatch(clearSelectedMails());

        dispatch(setEmailsToActiveFolder({ offset, mails, totalCount: count }));
      }
    })
    .catch(console.error);
};

export const deleteMailsFromSearchList = (config) => (dispatch) => {
  // Config contains next params: offset, limit, search, conversationsIds and unused sortBy, folder, mode
  // conversationsIds are selected emails that will be deleted
  const { conversationsIds } = config;

  return API.deleteMailsFromSearchList(config)
    .then(({ data }) => {
      const { offset, mails, count } = data;

      if (conversationsIds.length) {
        dispatch(setDelayedAction({
          delay: 30,
          type: 'delete',
          delayedActionText: `Message(s) Deleted!`,
          cancelRequestParams: { conversationsIds },
          actionId: `delete-from-search-${conversationsIds[0]}`,
        }));

        dispatch(clearSelectedMails());

        dispatch(setEmailsToActiveFolder({ offset, mails, totalCount: count }));
      }
    })
    .catch(console.error);
};

export const moveMailsToFolder = (config) => (dispatch) => {
  // Config contains next params: offset, sortBy, folder, mode, limit, conversationsIds, to
  // to and folder are parameters indicating to which folder mails should be moved and from where
  // conversationsIds are selected emails that will be deleted permanently
  // offset, sortBy, folder, mode, limit are sent to the backend to determine which emails to display on the current page
  const { conversationsIds, to, folder } = config;
  const actualFolderName = to === 'spam' ? 'junk' : to;

  return API.moveMailsToFolder(config)
    .then(({ data }) => {
      const { offset, mails, count } = data;

      dispatch(setDelayedAction({
        delay: 10,
        type: 'move',
        delayedActionText: `Moved to ${capitalizeFirstLetter(actualFolderName)}!`,
        cancelRequestParams: { to: folder, conversationsIds },
        actionId: `move-${conversationsIds[0]}`,
      }));

      dispatch(clearSelectedMails());

      dispatch(setEmailsToActiveFolder({ offset, mails, totalCount: count }));
    })
    .catch(console.error);
};

export const archiveMails = (config) => (dispatch) => {
  // Config contains next params: offset, sortBy, folder, mode, limit, conversationsIds
  // conversationsIds are selected emails that will be archived
  // offset, sortBy, folder, mode, limit are sent to the backend to determine which emails to display on the current page

  return API.archiveMails(config)
    .then(({ data }) => {
      const { offset, mails, count, mailMovesIds } = data;

      dispatch(setDelayedAction({
        delay: 10,
        type: 'archive',
        delayedActionText: `Moved to Archive!`,
        cancelRequestParams: { mailMovesIds },
        actionId: `archive-${mailMovesIds[0]}`,
      }));

      dispatch(clearSelectedMails());

      dispatch(setEmailsToActiveFolder({ offset, mails, totalCount: count }));
    })
    .catch(console.error);
};

export const toggleIsMailSelected = (conversationId) => ({
  type: TOGGLE_IS_MAIL_SELECTED,
  payload: { conversationId },
});

export const clearSelectedMails = () => ({
  type: CLEAR_SELECTED_MAILS,
});

export const markMailsNotSpam = (conversationId) => ({
  type: MARK_MAILS_NOT_SPAM,
  payload: conversationId,
});

export const markMailsNotSpamRequest = ({ conversationId }) => (dispatch) => {
  return API.markMailsNotSpam({ conversationId })
    .then(() => {
      dispatch(markMailsNotSpam(conversationId));
    })
    .catch(console.error);
}

export const undeleteMails = ({ msgId, conversationId }) => ({
  type: UNDELETE_MAILS,
  payload: { msgId, conversationId },
});

export const undeleteMailsRequest = ({ msgId, conversationId }) => (dispatch) => {
  return API.undeleteMails({ msgId, conversationId })
    .then(() => {
      dispatch(undeleteMails({ msgId, conversationId }));
    })
    .catch(console.error);
}

export const setDelayedAction = delayedAction => ({
  type: SET_DELAYED_ACTION,
  payload: delayedAction,
});

export const unsetDelayedAction = (delayedActionId) => ({
  type: UNSET_DELAYED_ACTION,
  payload: { delayedActionId },
});

export const clearDelayedActions = () => ({
  type: CLEAR_DELAYED_ACTIONS,
});

export const setComposePending = (pendingTypes) => ({
  type: SET_COMPOSE_PENDING,
  payload: pendingTypes
});

export const setComposeDraftId = (draftId) => ({
  type: SET_COMPOSE_DRAFT_ID,
  payload: draftId
});

export const setDraftStatus = (status) => ({
  type: SET_DRAFT_STATUS,
  payload: status
});

export const setMailListPending = (pending) => ({
  type: SET_MAIL_LIST_PENDING,
  payload: pending
});

export const setActiveFolder = (folder) => ({
  type: SET_ACTIVE_FOLDER,
  payload: folder
});

export const setActiveFolderTabName = (name) => ({
  type: SET_ACTIVE_FOLDER_TAB_NAME,
  payload: name
});

export const setActiveSortFilter = (sortOption) => ({
  type: SET_ACTIVE_SORT_FILTER,
  payload: sortOption
});

export const clearActiveFolder = () => ({
  type: CLEAR_ACTIVE_FOLDER,
});

export const updateSearch = (query) => ({
  type: UPDATE_SEARCH,
  payload: query,
});

export const getAvailableMailsForUser = (data) => ({
  type: GET_AVAILABLE_MAILS_FOR_USER,
  payload: data,
});

export const getUsersAvatarsForMail = (data) => ({
  type: GET_USERS_AVATARS_FOR_MAIL,
  payload: data,
});

export const getAvailableMailsForUserRequest = (userId) => async (dispatch) => {
  try {
    const { data } = await API.getAvailableMailsForUserRequest(userId);
    await dispatch(
      getAvailableMailsForUser(
        data.map((operator) => ({
          operatorName: operator.username,
          ...operator,
        }))
      )
    );
    const modeDefaultId = data.find((mail) => mail.is_default_type)?.id;

    const defaultTab = data.find((mail) => mail.is_default);

    const defaultModeOfOperationConfig = {
      value: modeDefaultId,
      activeFolderTabName: defaultTab?.username || null,
      defaultMailForUser: defaultTab || null,
    }

    await dispatch(
      changeDefaultModeOfOperation(defaultModeOfOperationConfig)
    )

  } catch (error) {
    return console.error(error);
  }
};

export const changeDefaultMailForUser = (emailId) => ({
  type: CHANGE_DEFAULT_MAIL_FOR_USER,
  payload: emailId,
});

export const changeDefaultModeOfOperation = (payload) => ({
  type: CHANGE_DEFAULT_MODE_OF_OPERATION,
  payload,
});

export const changeDefaultMailForUserRequest = (value, reset) => async (dispatch) => {
  try {
    const requestValue = isNumber(value) ? value : value.label;
    const { data } = await API.changeDefaultMailForUser(requestValue);
    data && dispatch(changeDefaultModeOfOperation({ value, reset }));
    data && isNumber(value) && dispatch(changeDefaultMailForUser(value));
  } catch (error) {
    return console.error(error);
  }
}

export const updateNotes = (conversationId, notes) => ({ type: UPDATE_NOTES, payload: { conversationId, notes } });

const initialState = {
  inbox: {
    ids: [],
    entities: {},
  },
  archive: {
    ids: [],
    entities: {},
  },
  sent: {
    ids: [],
    entities: {},
  },
  drafts: {
    ids: [],
    entities: {},
  },
  spam: {
    ids: [],
    entities: {},
  },
  trash: {
    ids: [],
    entities: {},
  },
  search: {
    ids: [],
    entities: {},
  },

  compose: {
    pending: {
      sendMsg: false,
      attach: false,
      delDraft: false,
    },
    draftId: null,
    draftStatus: null,
  },

  mailList: {
    search: '',
    activeFolder: null,
    mailListPending: true,
    activeFolderTabName: 'All Mails',
    activeSortFilter: MAIL_LIST_SORT_OPTIONS[0].value,
  },

  totalCount: 0,
  unreadCount: 0,
  unreadSpamCount: 0,
  offset: 0,

  activeMailChatId: null,
  activeMailChatMessages: [],

  selectedConversationsIds: [],

  delayedActions: [],

  availableMails: [],
  defaultMailForUser: null,
  usersAvatarsForMail: [],
  defaultModeOfOperation: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EMAILS: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          mailListPending: false,
        },
        [payload.type]: {
          ids: payload.mails.result,
          entities: payload.mails.entities,
        },
        totalCount: payload.totalCount,
      }
    }

    case ADD_EMAIL: {
      const { mail, message } = payload;
      const type = message.folder_type?.toLowerCase();
      
      if (!Object.values(LIST_TYPES).includes(type)) {
        return state;
      }
      
      const stateIds = state[type].ids;

      const getIds = () => {
        if (stateIds.includes(mail.conversationId)) {
          return [mail.conversationId, ...stateIds.filter(id => id !== mail.conversationId)];
        }
        else {
          return stateIds.length === MAIL_LIST_LIMIT
            ? [mail.conversationId, ...stateIds.slice(0, stateIds.length - 1)]
            : [mail.conversationId, ...stateIds];
        }
      }

      if (state.offset !== 0) {
        return {
          ...state,
          totalCount: !payload.isExisted 
            ? state.totalCount + 1 
            : state.totalCount,
          unreadCount: payload.unreadCount,
          unreadSpamCount: payload.unreadSpamCount,
        }
      }
      else {
        return {
          ...state,
          [type]: {
            ids: getIds(),
            entities: {
              ...state[type].entities,
              [mail.conversationId]: mail
            },
          },
          totalCount: !payload.isExisted
            ? state.totalCount + 1
            : state.totalCount,
          unreadCount: payload.unreadCount,
          unreadSpamCount: payload.unreadSpamCount,
        }
      }
    }

    case GET_FILTERED_EMAILS: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          mailListPending: false,
        },
        search: {
          ids: payload.mails.result,
          entities: payload.mails.entities,
        },
        totalCount: payload.totalCount,
      }
    }

    case UPDATE_SEARCH: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          search: payload,
        },
      }
    }

    case SET_EMAILS_TO_ACTIVE_FOLDER: {
      const { mails, totalCount, offset, folder } = payload;
      const { activeFolder } = state.mailList;
      const { result, entities } = normalize(mails, 'conversationId');
      const currentFolder = folder ? folder : activeFolder;

      if (currentFolder) {
        return {
          ...state,
          [currentFolder]: {
            ids: result,
            entities: entities,
          },
          offset,
          totalCount,
        };
      }

      return state;
    }

    case SET_OFFSET: {
      return {
        ...state,
        offset: payload,
      }
    }

    case SET_MAIL_LIST_PENDING: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          mailListPending: payload,
        },
      }
    }

    case SET_ACTIVE_FOLDER: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          activeFolder: payload,
        },
      }
    }

    case CLEAR_ACTIVE_FOLDER: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          activeFolder: null,
        },
      }
    }

    case SET_ACTIVE_SORT_FILTER: {
      return {
        ...state,
        mailList: {
          ...state.mailList,
          activeSortFilter: payload,
        },
      }
    }

    case SET_UNREAD_COUNT: {
      const { unreadCount, unreadSpamCount } = payload;

      return {
        ...state,
        unreadCount,
        unreadSpamCount,
      };
    }

    case SET_ACTIVE_MAIL_CHAT: {
      return {
        ...state,
        activeMailChatId: payload.id,
        activeMailChatMessages: payload.messages,
      }
    }

    case CLEAR_ACTIVE_MAIL_CHAT: {
      return {
        ...state,
        activeMailChatId: null,
        activeMailChatMessages: [],
      }
    }

    case ADD_MESSAGE_TO_MAIL_CHAT: {
      return {
        ...state,
        activeMailChatMessages: [...state.activeMailChatMessages, payload]
      }
    }

    case DELETE_MSG_FROM_MAIL_CHAT:
      return {
        ...state,
        activeMailChatMessages: state.activeMailChatMessages.filter(
          ({ id }) => id !== payload
        ),
      };

    case SET_MESSAGE_DRAFT: {
      const { msgId, draft } = payload;

      return {
        ...state,
        activeMailChatMessages: state.activeMailChatMessages.map(
          message => message.id === msgId ? { ...message, draft } : message
        ),
      };
    }

    case DELETE_MESSAGE_DRAFT:
      return {
        ...state,
        activeMailChatMessages: state.activeMailChatMessages.map(
          message => message.draft?.id === payload ? { ...message, draft: null } : message
        ),
      };

    // case DELETE_CONVERSATIONS_FROM_ALL_FOLDERS:
    //   const { conversationsIds } = payload;
    //   const updatedSelectedIds = state.selectedConversationsIds.filter((id) => !conversationsIds.includes(id));
    //
    //   return {
    //     ...state,
    //     selectedConversationsIds: updatedSelectedIds,
    //     [LIST_TYPES.inbox]: delConversFromFolder(state[LIST_TYPES.inbox], conversationsIds),
    //     [LIST_TYPES.sent]: delConversFromFolder(state[LIST_TYPES.sent], conversationsIds),
    //     [LIST_TYPES.spam]: delConversFromFolder(state[LIST_TYPES.spam], conversationsIds),
    //     [LIST_TYPES.drafts]: delConversFromFolder(state[LIST_TYPES.drafts], conversationsIds),
    //   };

    // case DELETE_CONVERSATIONS_FROM_FOLDER: {
    //   const { conversationsIds, folder } = payload;
    //   const normalizedFolder = folder?.toLowerCase();
    //   const updatedSelectedIds = state.selectedConversationsIds.filter((id) => !conversationsIds.includes(id));
    //
    //   if (!Object.values(LIST_TYPES).includes(normalizedFolder)) {
    //     return state;
    //   }
    //
    //   return {
    //     ...state,
    //     selectedConversationsIds: updatedSelectedIds,
    //     [normalizedFolder]: delConversFromFolder(state[normalizedFolder], conversationsIds),
    //   };
    // }

    case SET_IS_MAIL_STARRED:
      const { folder, conversationId, activeMailTab } = payload;

      let updatedIds = [...state[folder].ids];
      const updatedEntities = { ...state[folder].entities };

      if (activeMailTab === 'starred') {
        updatedIds = updatedIds.filter((id) => id !== conversationId);
        delete updatedEntities[conversationId];
      } else {
        updatedEntities[conversationId] = {
          ...updatedEntities[conversationId],
          is_starred: !updatedEntities[conversationId].is_starred,
        };
      }

      return {
        ...state,
        [folder]: {
          ...state[folder],
          ids: updatedIds,
          entities: updatedEntities,
        }
      }

    case MARK_MAILS_NOT_SPAM:
      return {
        ...state,
        [LIST_TYPES.spam]: delConversFromFolder(state[LIST_TYPES.spam], payload),
      };

    case TOGGLE_IS_MAIL_SELECTED:
      const { selectedConversationsIds } = state;

      return {
        ...state,
        selectedConversationsIds: selectedConversationsIds.includes(payload.conversationId)
          ? selectedConversationsIds.filter((id) => id !== payload.conversationId)
          : [...selectedConversationsIds, payload.conversationId],
      };

    case CLEAR_SELECTED_MAILS:
      return {
        ...state,
        selectedConversationsIds: [],
      }

    case UNDELETE_MAILS: {
      const { msgId, conversationId } = payload;

      if (msgId) {
        return {
          ...state,
          activeMailChatMessages: state.activeMailChatMessages.filter(
            ({ id }) => id !== msgId
          ),
        };
      }

      return {
        ...state,
        [LIST_TYPES.trash]: delConversFromFolder(state[LIST_TYPES.trash], conversationId),
      };
    }

    case SET_DELAYED_ACTION: {
      const { delayedActions } = state;
      const updatedDelayedActions = delayedActions.filter((action) => action.type !== payload.type);

      return {
        ...state,
        delayedActions: [...updatedDelayedActions, payload],
      };
    }

    case UNSET_DELAYED_ACTION:
      const { delayedActions } = state;
      const { delayedActionId } = payload;
      const updatedDelayedActions = delayedActions.filter((action) => action.actionId !== delayedActionId);

      return {
        ...state,
        delayedActions: updatedDelayedActions,
      };

    case CLEAR_DELAYED_ACTIONS:
      return {
        ...state,
        delayedActions: [],
      }

    case SET_COMPOSE_PENDING:
      return {
        ...state,
        compose: {
          ...state.compose,
          pending: {
            ...state.compose.pending,
            ...payload,
          }
        }
      }

    case SET_COMPOSE_DRAFT_ID:
      return {
        ...state,
        compose: {
          ...state.compose,
          draftId: payload,
        },
      }

    case SET_DRAFT_STATUS:
      return {
        ...state,
        compose: {
          ...state.compose,
          draftStatus: payload,
        },
      }
    
    case GET_AVAILABLE_MAILS_FOR_USER:
      return {
        ...state,
        availableMails: payload,
      }

    case GET_USERS_AVATARS_FOR_MAIL:
      return {
        ...state,
        usersAvatarsForMail: payload,
      }
    
    case CHANGE_DEFAULT_MAIL_FOR_USER:
      const defaultMail = state.availableMails.find(({ id }) => id ===  payload);

      return {
        ...state,
        defaultMailForUser: { operatorName: defaultMail.username, ...defaultMail},
      }

    case CHANGE_DEFAULT_MODE_OF_OPERATION:
      const defaultMode = state.availableMails.find(({ id }) =>
        isNumber(payload.value) ? id ===  payload.value : id === payload.value.value
      );

      if (payload.reset) {
        return {
          ...state,
          defaultModeOfOperation: {...defaultMode, is_default_type: 1},
          availableMails: state.availableMails.map((mail) => {
            const type = defaultMode.type
              return mail.id === defaultMode.id
              ? {...defaultMode, is_default_type: 1}
              : type === mail.type
                ? {...mail, is_default_type: 1}
                : {...mail, is_default_type: 0}
          }),
          mailList: {
            ...state.mailList,
            activeFolderTabName: 'All Mails',
          },
          defaultMailForUser: null,
        }
      }

      return {
        ...state,
        availableMails: state.availableMails.map((mail) => {
          const type = defaultMode.type;
          return mail.id === defaultMode.id
            ? { ...defaultMode, is_default_type: 1 }
            : type === mail.type
            ? { ...mail, is_default_type: 1 }
            : { ...mail, is_default_type: 0 };
        }),
        defaultModeOfOperation: { ...defaultMode, is_default_type: 1 },
        defaultMailForUser: payload.defaultMailForUser
          ? {
              operatorName: payload.defaultMailForUser.username,
              ...payload.defaultMailForUser,
            }
          : null,
        mailList: {
          ...state.mailList,
          activeFolderTabName: payload.activeFolderTabName || state.mailList.activeFolderTabName,
        },
      };

    case SET_ACTIVE_FOLDER_TAB_NAME:
      return {
        ...state,
        mailList: {
          ...state.mailList,
          activeFolderTabName: payload,
        }
      }

    case UPDATE_NOTES: {
      const { conversationId, notes } = payload;

      return {
        ...state,
        [state.mailList.activeFolder]: {
          ...state[state.mailList.activeFolder],
          entities: {
            ...state[state.mailList.activeFolder].entities,
            [conversationId]: {
              ...state[state.mailList.activeFolder].entities[conversationId],
              notes,
              is_notes: notes?.length ? '1' : '0',
            }
          }
        }
      }
    }

    default:
      return state;
  }
}

// Helpers

const delConversFromFolder = (folder, delIdOrIds) => {
  const entities = { ...folder.entities };
  const delIds = Array.isArray(delIdOrIds) ? delIdOrIds : [ delIdOrIds ];

  delIds.forEach((id) => {
    delete entities[id];
  });

  return {
    ids: folder.ids.filter((id) => !delIds.includes(id)),
    entities,
  };
};
