import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import API from 'api/api';
import { classModifier } from 'utils';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { GIRLS_LIMIT } from 'redux/ducks/contacts';

import BookingSelectedProfile from './BookingSelectedProfile';
import SearchListDivaItem from 'components/SearchList/components/SearchListDivaItem/SearchListDivaItem';
import ICONS from 'assets/icons';
import DuoBookingForm from 'components/DuoBookingForm/DuoBookingForm';
import BookingEditFieldsItem from 'components/BookingEditFieldsItem/BookingEditFieldsItem';


const PAGE_SIZE = 25;


const BookingSelectedProfiles = (props) => {
  const {
    values,
    changeForm,
    handleUpdateBooking,
    disabled,
    notForIds,
  } = props;

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [isAddProfileFormOpenned, setIsAddProfileFormOpenned] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isProfileDeletedJustNow, setIsProfileDeletedJustNow] = useState(false);

  const valuesRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const profilesList = [];

    if (values.profile) {
      profilesList.push(values.profile);
    }
    
    if (values.escort?.id) {
      profilesList.push(values.escort);
    }
    
    setSelectedProfiles(profilesList);
  }, [values])

  useEffect(() => {
    valuesRef.current = values;
  }, [values])

  useEffect(() =>{
    if (isProfileDeletedJustNow) {
      selectedProfiles.length && handleUpdateBooking({ values: valuesRef.current });
      setIsProfileDeletedJustNow(false);
    }
  }, [isProfileDeletedJustNow, selectedProfiles])

  const clearForm = (newProfiles) => {
    changeForm('profile', null);
    changeForm('escort', null);

    if (newProfiles[0]) {
      changeForm('profile', newProfiles[0]);
      changeForm('profileId', newProfiles[0].id);
    } 
    newProfiles[1] && changeForm('escort', newProfiles[1]);

    if (!newProfiles.length) {
      changeForm('rate', null);
      changeForm('date', null);
      changeForm('type', null);
      changeForm('address', null);
      changeForm('bookingDuration', null);
      changeForm('discount', null);
      changeForm('taxi', null);
    }
  }

  const handleUpdateProfile = () => {
    const fetchData = (params) => {
      return API.getActiveDivaGirls({ limit: GIRLS_LIMIT, search: params.query, ...params })
        .then(({ data }) => ({
          newItems: data.result,
          newHasMore: data.result?.length === PAGE_SIZE,
        }))
    };

    const handleProfileChoose = (data) => {
      setIsAddProfileFormOpenned(true);
      setSelectedProfile(data);
      dispatch(closeModal(MODAL_TYPES.searchList));
    }

    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData,
      itemComponent: SearchListDivaItem,
      onChoose: handleProfileChoose,
      bookingDivaId: selectedProfiles.map(profile => profile.id),
    }))
  }

  const handleDeleteProfile = (profileIndex) => {
    const newProfiles = [...selectedProfiles];
    newProfiles.splice(profileIndex, 1);
    clearForm(newProfiles);

    setSelectedProfiles(newProfiles);

    setIsProfileDeletedJustNow(true);
  }

  return (
    <>
      {!selectedProfiles.length && (
        <button
          type='button'
          className={classModifier('booking-edit-form__btn', 'add-escort')}
          onClick={handleUpdateProfile}
        >
          Add escort
          <ICONS.plusCircle className={classModifier('booking-edit-form__btn-icon', 'add-escort')}/>
        </button>
      )}

      {isAddProfileFormOpenned && (
        <DuoBookingForm
          values={values}
          formChange={changeForm}
          setDuoFieldOpen={setIsAddProfileFormOpenned}
          updateBooking={handleUpdateBooking}
          tempProfile={selectedProfile}
        >
          <BookingEditFieldsItem
            fieldName="profile"
            profile={selectedProfile}
          />
        </DuoBookingForm>
      )}

      {
        selectedProfiles.map((profile, index) => {
          return (
            <BookingSelectedProfile
              key={profile.id}
              profile={profile}
              notForIds={notForIds}
              setProfile={handleUpdateProfile}
              deleteProfile={() => handleDeleteProfile(index)}
              isDisabled={disabled}
            />
          );
        })
      }
    </>
  )
}

export default BookingSelectedProfiles;
