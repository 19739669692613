import classNames from 'classnames';
import Select from 'components/UI/Select/Select';
import { SELECT_THEMES } from 'config/constants';
import { useDidUpdate } from 'hooks';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetMapLocation, updateMapLocation } from 'redux/ducks/map';

const GirlsMapModalSelectFilter = (props) => {
  const {
    name,
    request,
    requiredField,
    requiredFilter,
  } = props;

  const { location } = useSelector((state) => state.map);

  const [key, setKey] = useState(`${name}-${name}`);
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const additionalDataFromRequiredFilter = location[requiredFilter];

  useDidUpdate(async () => {
    if (additionalDataFromRequiredFilter) {
      const config = {
        [requiredFilter]: !!requiredFilter && additionalDataFromRequiredFilter[requiredField],
      };

      const { data } = await request(config);

      const options = data.map((item) => ({
          ...item,
          label: item[name === 'area' ? 'location' : name],
          value: item[name === 'area' ? 'location' : name],
        })
      );

      setOptions(options);
    }

    const selectedRequiredFilter = additionalDataFromRequiredFilter
      ? additionalDataFromRequiredFilter[requiredField]
      : name;

    if (name !== 'country') {
      dispatch(updateMapLocation(name, null));  // Clear connected filter
      setKey(`${name}-${selectedRequiredFilter}`);
    }

  }, [additionalDataFromRequiredFilter]);

  const loadOptions = async (inputValue, _, { page }) => {
    if (!requiredFilter) {
      const config = {
        name: inputValue,
        offset: page * 20,
        [requiredFilter]: !!requiredFilter && additionalDataFromRequiredFilter[requiredField],
      };

      const { data } = await request(config);

      const options = data.map((item) => ({
          ...item,
          label: item[name === 'area' ? 'location' : name],
          value: item[name === 'area' ? 'location' : name],
        })
      );

      return {
        options: options,
        hasMore: options.length >= 20,
        additional: {
          page: page + 1
        }
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        }
      };
    }
  };

  const handleChange = (selectedOption) => {
    if (name === 'country' && !selectedOption) {
      dispatch(resetMapLocation());
      return;
    }

    if (name === 'city' && !selectedOption) {
      dispatch(updateMapLocation('area', selectedOption));
    }

    dispatch(updateMapLocation(name, selectedOption));
  };

  return (
    <div className="girls-map__filter-wrap">
      <label
        className='girls-map__filter-label'
        htmlFor={name}
      >
        {name}
      </label>

      <Select
        key={key}
        name={name}
        value={location[name]}
        type={SELECT_THEMES.LIGHT}
        className={classNames('react-select', 'girls-map__filter')}
        // classNamePrefix='react-select'
        isClearable
        placeholder={name}
        inputId={name}
        loadingMessage={() => null}
        onChange={handleChange}
        isSearchable
        isDisabled={!!requiredFilter && (!additionalDataFromRequiredFilter || !options.length)}
        additional={{
          page: 0
        }}
        {...(!requiredFilter ? { loadOptions } : { options })}
      />
    </div>
  );
};

export default GirlsMapModalSelectFilter;

