import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Scrollbars } from "react-custom-scrollbars-2";

import API from "api/api";
import { usePrevious } from 'hooks';
import { openModal } from 'redux/ducks/activeWindows';
import { changeGirlAgent, updateAgentsGirlIds } from 'redux/ducks/contacts';
import { selectContactById, selectAgentContactById, selectAgentsList } from 'redux/selectors/selectors';
import { CONTACT_TYPES } from 'config/constants';

import './ContactCard.scss';
import ContactInfo from './components/ContactInfo/ContactInfo';
import ContactEditForm from './components/EditContactForm/ContactEditForm';
import FeedbackList from 'components/FeedbackList/FeedbackList';
import FeedbackAddBtn from 'components/FeedbackAddBtn/FeedbackAddBtn';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import ContactLogsTab from 'containers/AdrBookContactFormWrap/components/AdrBookContactFormTabs/components/ContactLogsTab/ContactLogsTab';
import ContactBookings from './components/ContactBookings/ContactBookings';

const ContactCard = props => {
  const {
    activeRecipient = {},
    defaultAgent,
    defaultTab = 0,
    disableEditTab = false,
    agents
  } = props;

  const [feedbackList, setFeedbackList] = useState([]);
  const [notForClients, setNotForClients] = useState([]);

  const arId = usePrevious(activeRecipient.id);

  useEffect(() => {
    if (activeRecipient.id) {
      API.getClientNotFor(activeRecipient.id)
        .then(({ data }) => {
          setNotForClients(data);
        })
        .catch(err => console.error(err));
    }
  }, [activeRecipient.id]);

  useEffect(() => {
    if (activeRecipient.not_for_clients) {
      setNotForClients(activeRecipient.not_for_clients)
    }
  }, [activeRecipient.not_for_clients]);

  useEffect(() => {
    // We close modal in main page when we open them in Contact Editor and delete this contact in Address Book

    if(!activeRecipient.id && !!arId) {
      props.closeModal();
    }
  }, [activeRecipient.id]);

  useEffect(() => {
    return () => {
      document.dispatchEvent(new CustomEvent('closeModal'));
    }
  }, []);

  return (
    <div className="contact-card">
      <Tabs
        defaultIndex={defaultTab}
      >
        <TabList>
          <Tab>Info</Tab>
          {!disableEditTab && <Tab>Edit</Tab>}
          <Tab>Contact Log</Tab>
          <Tab className='react-tabs__tab react-tabs__tab--sessions'>
            Bookings
          </Tab>
          <Tab>Feedbacks</Tab>

        </TabList>

        <TabPanel>
          <Scrollbars
            renderThumbVertical={CustomScrollbarThumb}
            renderTrackVertical={CustomScrollbarTrack}
          >
            <ContactInfo
              contact={activeRecipient.id}
              activeRecipient={activeRecipient}
              agent={defaultAgent}
              closeModal={props.closeModal}
              openModal={props.openModal}
              notForClients={notForClients}
            />
          </Scrollbars>
        </TabPanel>

        {!disableEditTab && (
          <TabPanel>
            <Scrollbars
              renderThumbVertical={CustomScrollbarThumb}
              renderTrackVertical={CustomScrollbarTrack}
            >
              <ContactEditForm
                contact={activeRecipient}
                contactType={activeRecipient.type}
                isAddPhoneNumberOnMount={props.isAddPhoneNumberOnMount}
                isAddEmailFieldOnMount={props.isAddEmailFieldOnMount}
                saveOnExit={props.saveOnExit}
                autoFocusInput={props.autoFocusInput}
                notForClients={notForClients}
                setNotForClients={setNotForClients}
                agents={agents}
              />
            </Scrollbars>
          </TabPanel>
        )}

        <TabPanel>
          <ContactLogsTab
            id={activeRecipient.id}
            name={activeRecipient.fn}
          />
        </TabPanel>

        <TabPanel>
          {(activeRecipient.type !== CONTACT_TYPES.GIRL
          || activeRecipient.type === CONTACT_TYPES.GIRL && activeRecipient.diva_default_id) && (
            <ContactBookings activeRecipient={activeRecipient} />
          )}
        </TabPanel>

        <TabPanel>
          <div className='contact-card__feedbacks-tab'>
            <div>
              <FeedbackAddBtn
                activeContact={activeRecipient}
                setFeedbackList={setFeedbackList}
              />
            </div>

            <FeedbackList
              contactId={activeRecipient.id}
              feedbackList={feedbackList}
              setFeedbackList={setFeedbackList}
            />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  activeRecipient: typeof ownProps.contact === "object" ? ownProps.contact : selectContactById(state, ownProps.contact),
  defaultAgent: selectAgentContactById(state, ownProps.contact),
  agents: selectAgentsList(state),
});

const mapDispatchToProps = {
  changeGirlAgent,
  updateAgentsGirlIds,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);
