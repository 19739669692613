
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useCancelToken, useDidUpdate } from 'hooks';
import { getContactsById, GIRLS_LIMIT } from 'redux/ducks/contacts';
import { updateBooking } from 'redux/ducks/sessions';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';
import { classModifier, convertDateFromUTC, getContactAvatar } from 'utils';
import { BOOKING_DETAILS_DATE_CONFIG, BOOKING_MESSAGES_DATE_CONFIG } from 'config/dates-сonfig';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { BOOKING_STATUS_FILTERS } from 'config/constants';
import getCurrentSelectedRate from 'utils/getBookingSelectedRate';
import getIconTypeByInteractionId from 'utils/getIconTypeByInteractionId';

import {
  selectUserTimezone,
  selectActiveSessionId,
  selectAllRequirements,
  selectActiveSessionContact,
  selectBookedProfilesListById,
  selectContactById,
} from 'redux/selectors/selectors';
import {
  deleteBooking,
  setUpdateBookingsPending,
  updateBookingByValues,
  deleteAttachment,
  setBookingLog,
  clearBookingLog
} from 'redux/ducks/bookings';

import './BookingEditForm.scss';
import DateTime from 'components/DateTime';
import BookingLog from './BookingLog/BookingLog';
import Spinner from 'components/UI/Spinner/Spinner';
import BookingEditAutoSave from './BookingEditAutoSave';
import PrebookingsList from './PrebookingsList/PrebookingsList';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import DuoBookingForm from 'components/DuoBookingForm/DuoBookingForm';
import { FeedbackContactField } from 'components/FeedbackForm/components';
import BookingNotes from 'components/BookingForm/components/BookingNotes';
import BookingLocationEditor from './BookingLocationEditor/BookingLocationEditor';
import BookingRequirements from 'components/BookingRequirements/BookingRequirements';
import OverlappedBookingsMsg from 'components/OverlappedBookingsMsg/OverlappedBookingsMsg';
import BookingEditFieldsItem from 'components/BookingEditFieldsItem/BookingEditFieldsItem';
import BookingSelectedProfile from 'components/BookingForm/components/BookingSelectedProfile';
import BookingFormStatusField from 'components/BookingForm/components/BookingFormStatusField';
import SearchListDivaItem from 'components/SearchList/components/SearchListDivaItem/SearchListDivaItem';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import BookingSpecialRequestField from 'components/BookingForm/components/BookingSpecialRequestField/BookingSpecialRequestField';
import BookingSpecialRequestMessages from 'components/BookingForm/components/BookingSpecialRequestMessages/BookingSpecialRequestMessages';
import OverflowedTags from 'components/OverflowedTags/OverflowedTags';
import ContactTags from 'components/ContactTags/ContactTags';
import BookingSelectedProfiles from 'components/BookingForm/components/BookingSelectedProfiles';
import Button from 'components/Button/Button';


const CallerInfo = ({ callerId }) => {
  const caller = useSelector((state) => selectContactById(state, callerId));
  const dispatch = useDispatch();

  const { fn, tels, emails, interactionObject } = caller || {};
  const { type: interactionType } = interactionObject || {};

  const lastInteractionType = getIconTypeByInteractionId(interactionType);
  const LastInteractionIcon = ICONS[lastInteractionType];

  useEffect(() => {
    if (!caller && callerId) {
      dispatch(getContactsById(callerId));
    }
  }, [caller, callerId]);

  if (!caller) {
    return (
      <div className='booking-edit-form__selected-profiles-spinner-wrapper'>
        <Spinner spinnerSize={50} />
      </div>
    )
  }

  const handleNameClick = () => {
    dispatch(openModal(MODAL_TYPES.contactCard, { contact: caller }))
  }

  return (
    <>
      <div className='booking-edit-form__client-info'>
        <div className='booking-edit-form__avatar-container'>
          <LazyLoadImage src={getContactAvatar(caller)} alt="ava" />
          {LastInteractionIcon && (
            <LastInteractionIcon className={classModifier("booking-edit-form__channel-type", lastInteractionType)} />
          )}
        </div>

        <div className='booking-edit-form__client-text-content'>
          <span
            className='booking-edit-form__client-name'
            onClick={handleNameClick}
          >
            {fn}
          </span>

          <span className='booking-edit-form__client-contact'>
            {tels?.[0]?.tel || emails?.[0]?.email || '(no data)'}
          </span>
        </div>
      </div>

      <ContactTags
        className='booking-edit-form__client-tags-wrapper'
        tagClassName="booking-edit-form__client-tag"
        callers={caller}
        wrapper={OverflowedTags}
      />
    </>
  )
}


const BookingEditForm = ({
  agents,
  openModal,
  userHour12,
  userTimezone,
  getActiveDivaGirls,
  activeContact,
  activeSessionId,
  updateBookingsList,
  activeSessionContact,
  updateBookingByValues,
  dispatchCloseEvent,
  setBookingLog,
  clearBookingLog,
  bookingLog,
  getContactsById,
  ...props
}) => {

  const [booking, setBooking] = useState(props.updatedBooking);

  const groupGirlsData = typeof (booking?.group_girls) === 'string' ? JSON.parse(booking?.group_girls) : booking?.group_girls

  const soloId = booking.profile_id;
  const duoId = booking?.group_girls
    ? Object.keys(groupGirlsData).find(id => Number(id) !== Number(booking.profile_id))
    : booking.profile_id_duo

  const divaGirls = useSelector(state => state.divaGirls.entities);
  const soloProfileFromState = useSelector(state => state.divaGirls.entities[soloId] || null);
  const duoProfileFromState = useSelector(state => state.divaGirls.entities[duoId] || null);

  const duoBookingData = booking?.groupGirls?.find(groupGirlsBooking => groupGirlsBooking.bookingId !== booking.id)
  const soloBookingData = booking?.groupGirls?.find(groupGirlsBooking => groupGirlsBooking.bookingId === booking.id)
  const notForIds = booking?.caller.notForClients?.map(i => i.diva_default_id);

  const [isDuoFieldOpen, setDuoFieldOpen] = useState(false);
  const [profile, setProfile] = useState(soloProfileFromState);
  const [duoProfile, setDuoProfile] = useState(duoProfileFromState);
  const [overlaps, setOverlaps] = useState({ conflictBookings: [], isOverlaps: false });
  const [isLocationEditorOpen, setIsLocationEditorOpen] = useState(false);
  const [isRequestFieldOpen, setIsRequestFieldOpen] = useState(false);
  const [isRequestMessageFieldOpen, setIsRequestMessageFieldOpen] = useState(false);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(true);
  const [tempProfile, setTempProfile] = useState(null);

  const isLoading = useSelector((state) => state.bookings.updateBookingsPending);
  const attachments = useSelector((state) => state.bookings.entities[booking.id]?.notes_attachments);

  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  const caller = useSelector((state) => selectContactById(state, activeSessionContact?.id || booking.callerId || booking.caller_id));


  const isClientAMember = useMemo(() => {
    return caller?.fn?.split(" ")?.find(word => word.toLowerCase() === 'member')
  }, [caller]);

  const checkMemberPrices = ({ prices, prices_member, type, duration }) => {
    return prices?.reduce((result, price, index) => {
      const memberPrice = prices_member[index];
      if (!result && isClientAMember && memberPrice && memberPrice[type] && memberPrice.name === duration) {
        return memberPrice;
      } else if (!result && price && price.name === duration) {
        return price;
      }
      return result;
    }, null);
  };

  useEffect(() => {
    if (props.updatedBooking?.isNotFull) {
      API.getBookingById(booking.id)
        .then(({ data }) => {
          setBooking(data);

          props.updateBooking(data.session_id, data);

          setIsLoadingBooking(false);
        })
        .catch(console.error)
    } else {
      setBooking(props.updatedBooking || props.booking);
      setIsLoadingBooking(false);
    }
  }, []);

  const getBookingLog = () => {
    API.getSessionLog(booking.id)
      .then(({ data }) => setBookingLog(data))
      .catch(console.error);
  }

  useEffect(() => {
    getBookingLog();

    return clearBookingLog;
  }, []);

  useEffect(() => {
    if (!props.updatedBooking) return;

    setBooking(props.updatedBooking);
  }, [props.updatedBooking?.groupGirls?.length]);

  const getSelectedRate = (bookingData) => {
    if (profile && !(profile?.isNotExist || duoProfile?.isNotExist)) {
      let currPrices;
      const { prices, prices_member, prices_secret } = profile;

      switch (bookingData.priceType) {
        case 'S':
          currPrices = prices_secret;
          break;
        case 'M':
          currPrices = prices_member;
          break;
        default:
          currPrices = prices;
          break;
      }

      const findCurrentPrice = currPrices.find(item => item.name === bookingData.duration);

      const currPriceByType = findCurrentPrice ? findCurrentPrice[bookingData.type] : "";

      return {
        label: Number(currPriceByType) ? `£${currPriceByType}` : null,
        value: Number(currPriceByType) || null,
        type: bookingData.priceType || 'D'
      }
    } else {
      return {
        label: null,
        value: Number(bookingData.rate),
        type: 'D',
      };
    }
  };

  const getCheckedServices = (currentProfile, bookingServices) => {
    if (currentProfile?.services) {
      return currentProfile?.services.map(item => {
        const checked = (bookingServices || []).some(i => i.name === item.name);
        return { ...item, checked };
      })
    } else return [];
  };

  const bookingRateUpdate = (bookingDuration, type, currentProfile) => {
    // replace this func

    const rateByType = getCurrentSelectedRate(type, bookingDuration, currentProfile);
    const rateMember = rateByType.find(item => isClientAMember && item.type === 'M' && item.value)
    const rateDefault = rateByType.find(item => item.type === (booking.priceType || 'D'))

    return rateMember || rateDefault || '';
  };

  const getDuoBD = () => {
    if (duoBookingData?.duration) {
      const preparedValue = checkMemberPrices({
        prices: duoProfile?.prices,
        prices_member: duoProfile?.prices_member,
        type: duoBookingData?.type,
        duration: duoBookingData?.duration
      });

      return {
        label: duoBookingData?.duration,
        value: preparedValue ? preparedValue[duoBookingData?.type] : "",
      };
    } else {
      return {};
    }
  };

  const memberBDValue = profile?.prices_member?.find(item => item.name === booking.duration)?.[booking.type];
  const defaultBDValue = profile?.prices?.find(item => item.name === booking.duration)?.[booking.type];

  const initialValues = useMemo(() => {
    const duoBookingDuration = getDuoBD();

    return {
      profile,
      meetingType: booking.meeting_type,
      operatorId: booking.usersIds?.[0],
      profileId: profile?.id,
      services: getCheckedServices(profile, booking.services),
      bookingStatus: Object.values(BOOKING_STATUS_FILTERS).find(item => item.label === booking.confirmation_status),
      date: +convertDateFromUTC(booking.date),
      scheduleMessages: booking.scheduleMessages?.map(msg => ({
        ...msg,
        date: +convertDateFromUTC(msg.date) || msg.date,
      })) || [],
      bookingDuration: {
        label: booking.duration,
        value: isClientAMember && memberBDValue || defaultBDValue,
      },
      type: booking.type,
      requestText: Array.isArray(booking.requests) && booking.requests.filter((req) => !req.is_from_message)[0]?.description,
      requestData: Array.isArray(booking.requests) && booking.requests.filter((req) => !req.is_from_message)[0],
      taxi: { label: `£ ${booking.taxiFee}`, value: !booking.taxiFee ? null : booking.taxiFee },
      taxiNote: booking.taxi_note,
      discount: { label: `£ ${booking.discount}`, value: !booking.discount ? null : booking.discount },
      rate: getSelectedRate(booking),
      requirements: duoProfile
        ? soloBookingData?.requirements?.map(req => ({ ...req, isChecked: !!req.isChecked }))
        : booking.requirements?.map(req => ({ ...req, isChecked: !!req.isChecked })),
      notes: booking.notes || '',
      priceType: booking.priceType,
      escort: duoProfile && duoBookingData && {
        ...duoProfile,
        type: duoBookingData.type,
        rate: bookingRateUpdate(duoBookingDuration, duoBookingData.type, duoProfile),
        discount: {
          label: `£ ${duoBookingData.discount}`,
          value: +duoBookingData.discount || null,
        },
        taxi: {
          label: `£ ${duoBookingData.taxi_fee}`,
          value: +duoBookingData.taxi_fee || null
        },
        bookingDuration: duoBookingDuration,
        taxiNote: duoBookingData.taxiNote,
        services: getCheckedServices(duoProfile, duoBookingData.services),
      },
      duoRequirements: duoBookingData?.requirements?.map(req => ({ ...req, isChecked: !!req.isChecked })) || props.requirements,
      notesAttachments: booking.notesAttachments || [],
      address: booking.address && {
        uuid: booking.address.uuid,
        type: String(booking.address.type),
        locationName: booking.address.address,
        latitude: +booking.address.latitude,
        longitude: +booking.address.longitude,
      },
      uuidGroup: booking.uuid_group,
      specialRequestMsgs: Array.isArray(booking.requests) ? booking.requests.filter((req) => req.is_from_message) : [],
      is_success: booking.is_success,
      reason: booking.reason,
      description: booking.description,
    }
  }, [profile, soloProfileFromState, duoProfile, booking, duoBookingData, isClientAMember]);

  const [prevFormState, setPrevFormState] = useState(initialValues);

  const dispatch = useDispatch();

  // const caller = useMemo(() => ({
  //   ...booking.caller,
  //   tels: booking.caller.tels || [],
  //   emails: booking.caller.emails || [],
  //   photo: booking.caller?.photo
  //     ? { min: booking.caller.photo }
  //     : null,
  //   notForIds: booking.caller.notForClients?.map(i => i.diva_default_id),
  // }), [booking]);

  const incallAddress = useMemo(() => ({
    locationName: profile?.place,
    latitude: +profile?.latitude,
    longitude: +profile?.longitude,
  }), [profile]);

  const getOperatorName = (operatorId) => {
    const operatorsList = Object.values(props.operators);
    const { id: currentUserID, username: currentUserName } = props.currentUser;

    return currentUserID === operatorId
      ? currentUserName
      : operatorsList.find(op => op.id === operatorId)?.username || '(not found)';
  };

  useEffect(() => {
    const { updatedBooking } = props;

    if (updatedBooking) {
      const groupGirlsData = typeof (updatedBooking?.group_girls) === 'string'
        ? JSON.parse(updatedBooking?.group_girls)
        : updatedBooking?.group_girls
  
      const soloId = booking.profile_id;
      const duoId = booking?.group_girls
        ? Number(Object.keys(groupGirlsData).find(id => Number(id) !== Number(booking.profile_id)))
        : booking.profile_id_duo
  
      const updatedSoloProfile = divaGirls[soloId];
      const updatedDuoProfile = divaGirls[duoId];

      const preparedBDValue = checkMemberPrices({
        prices: prevFormState?.profile?.prices,
        prices_member: prevFormState?.profile?.prices_member,
        type: updatedBooking.type,
        duration: updatedBooking.duration,
      })

      setPrevFormState({
        ...prevFormState,
        taxi: { label: `£ ${updatedBooking.taxiFee}`, value: updatedBooking.taxiFee },
        taxiNote: updatedBooking.taxi_note,
        rate: getSelectedRate(updatedBooking),
        discount: { label: `£ ${updatedBooking.discount}`, value: updatedBooking.discount },
        type: updatedBooking.type,
        date: +convertDateFromUTC(updatedBooking.date),
        operatorId: updatedBooking.usersIds?.[0],
        profileId: updatedBooking.profile_id,
        profile: updatedSoloProfile,
        escort: updatedDuoProfile,
        services: updatedBooking?.services?.map(item => {
          const checked = booking.services.some(i => i.name === item.name);
          return { ...item, checked };
        }) || [],
        bookingStatus: Object.values(BOOKING_STATUS_FILTERS).find(item => item.label === updatedBooking.confirmation_status),
        scheduleMessages: updatedBooking.scheduleMessages,
        bookingDuration: {
          label: updatedBooking.duration,
          value: preparedBDValue ? preparedBDValue[updatedBooking.type] : '',
        },
        requestText: Array.isArray(updatedBooking.requests) && updatedBooking.requests.filter((req) => !req.is_from_message)[0]?.description,

        requirements: duoProfile
          ? soloBookingData?.requirements?.map(req => ({ ...req, isChecked: !!req.isChecked }))
          : updatedBooking?.requirements?.map(req => ({ ...req, isChecked: !!req.isChecked })) || updatedBooking.requirements,
        duoRequirements: duoBookingData?.requirements?.map(req => ({ ...req, isChecked: !!req.isChecked })) || props.requirements,
        notes: updatedBooking.notes,
        notesAttachments: updatedBooking.notes_attachments,
        address: updatedBooking.address && {
          ...updatedBooking.address,
          type: String(updatedBooking.address.type),
          latitude: +updatedBooking.address.latitude,
          longitude: +updatedBooking.address.longitude,
          locationName: updatedBooking.address?.address
        },
        specialRequestMsgs: Array.isArray(updatedBooking.requests) ? updatedBooking.requests.filter((req) => req.is_from_message) : [],
      })
    }
  }, [props.updatedBooking, divaGirls]);

  useEffect(() => {
    // request to load missing profiles

    const profileIdsForLoading = [
      !soloProfileFromState && soloId,
      !duoProfileFromState && duoId
    ].filter(Boolean);

    if (!!profileIdsForLoading.length) {
      setIsLoadingProfiles(true);

      getActiveDivaGirls({ params: { 'filter-profileid': profileIdsForLoading }, quickBooking: true })
        .then((data) => {
          setIsLoadingProfiles(false)

          if (!data || !data.girls.length) {
            const missingProfiles = profileIdsForLoading
              .filter(id => !Object.values(data.girls).map(girl => girl.id).includes(id));

            missingProfiles.forEach(missingId => {
              API.getContactByProfileId(missingId)
                .then(({ data }) => {
                  const action = missingId === soloId ? setProfile : setDuoProfile;

                  action({
                    id: missingId,
                    prices: [],
                    prices_member: [],
                    prices_secret: [],
                    services: null,
                    place: data.adr || '',
                    name: data.name || data.short_name || data.headerName,
                    agent_id: data.agentId,
                    age: data.age,
                    dress: '-',
                    avatar: data.photo?.src || '',
                    agents: [],
                    height: '-',
                    nationality: '-',
                    is_available_now: 0,
                    is_available_today: 0,
                    breast_size: '-',
                    images_info: [],
                    isNotExist: true,
                  })
                })
            });
          }
      });
    }
  }, [props.updatedBooking, props.updatedBooking?.groupGirls?.length]);

  useDidUpdate(() => {
    setProfile(soloProfileFromState || null);
    setDuoProfile(duoProfileFromState || null);
  }, [props.bookingStateProfiles.length, soloProfileFromState, duoProfileFromState]);

  const handleUpdateBooking = _.debounce(({ values, saveUUID, clearAttachments = () => { }, forceBooking = false }) => {
    if (booking.is_deleted) return;

    updateBookingByValues(values, booking, cancelPrevRequest, newCancelToken, booking.id, forceBooking)
      .then(() => {
        isDuoFieldOpen && setDuoFieldOpen(false);
        setOverlaps({ conflictBookings: [], isOverlaps: false })
      })
      .catch((err) => {
        console.error(err)
        if (err.response?.data.code === 403) {
          const { conflictBookings } = JSON.parse(err.response.data.message);

          setDuoFieldOpen(false)

          setOverlaps({
            conflictBookings,
            isOverlaps: true
          });
        }
      })
      .finally(() => {
        dispatch(setUpdateBookingsPending(false));
        clearAttachments()
      });
  });

  const renderTotalRate = (values) => {
    let duoTotalRate = 0;

    if (values.escort) {
      duoTotalRate = getServicesRate(values.escort?.services) +
        (values.escort.rate?.value || 0) -
        (values.escort.discount?.value || 0) +
        (values.escort.taxi?.value || 0)
    }

    return values.rate?.value
      ? <span className='booking-form__booking-total-rate'>
        £ {getServicesRate(values.services) +
          (values.rate?.value || 0) -
          (values.discount?.value || 0) +
          (values.taxi?.value || 0) +
          duoTotalRate
        }
      </span>
      : <span>-</span>
  };

  const getServicesRate = (values) => {
    return values?.reduce((acc, item) => item.checked ? acc + item.price : acc + 0, 0);
  };

  const renderCheckedServices = values => values
    .filter(item => !!item.checked)
    .map((item, idx) => (
      <span key={idx}>
        {!!idx && ', '}
        {item.name}
      </span>
    ));

  const renderPaidServices = values => values
    .filter(item => item.price)
    .filter(item => !!item.checked)
    .map((item, idx, arr) => {
      const title = `£ ${item.price} ${item.name}`;
      const isComma = idx !== arr.length - 1;
      return (
        <span key={idx}>
          {title}
          {isComma && ', '}
          &nbsp;
        </span>
      )
    });

  const renderTypeDurationDetails = values => {
    return <>
      {values.type && <span className='booking-edit-form__booking-details-type'> {values.type} / </span>}
      {values.bookingDuration && <span>{values.bookingDuration.label} / </span>}
      {values.bookingDuration && <span>£{values.bookingDuration.value}</span>}
    </>
  };

  const getSelectedProfilesList = useCallback(values => {
    const profilesList = [];

    if (values.profile) {
      profilesList.push(values.profile);
    }

    if (values.escort?.id) {
      profilesList.push(values.escort);
    }

    return profilesList;
  }, [booking]);

  const handleOpenItemsModal = (form, fieldType, profileIds = [], isDuoProfileEdit) => {
    const PAGE_SIZE = 25;
    const SOURCE_TYPES = {
      operator: 'operator',
      diva: 'diva',
    };

    const fetchDataRequests = {
      operator: (params) => API.getOperatorsForFeedback({ ...params, limit: PAGE_SIZE }),
      diva: ({ offset, query, cancelToken, part }) => API.getActiveDivaGirls({ limit: GIRLS_LIMIT, part, offset, search: query, cancelToken }),
    };

    const fetchData = () => {
      const requestFunction = fetchDataRequests[fieldType];

      // params contain offset, query, cancelToken
      return (params) => requestFunction(params)
        .then(({ data }) => ({
          newItems: fieldType === SOURCE_TYPES.diva ? data.result : data, // data.result - for diva
          newHasMore: data.result?.length === PAGE_SIZE || data.length === PAGE_SIZE,
        }));
    };

    const subMenuItemsActions = {
      operator: () => openModal(MODAL_TYPES.searchList, {
        fetchData: fetchData(),
        itemComponent: SearchListContactItem,
        onChoose: handleChoose,
      }),
      diva: () => openModal(MODAL_TYPES.searchList, {
        fetchData: fetchData(),
        itemComponent: SearchListDivaItem,
        onChoose: handleChoose,
        bookingDivaId: profileIds
      }),
    };

    const handleChoose = (data) => {
      fieldType === SOURCE_TYPES.operator && form.change('operatorId', data.id);

      if (fieldType === SOURCE_TYPES.diva) {
        const rateValue = data.prices?.find(price => price.name === booking.duration)[booking.type];

        if (isDuoProfileEdit) {
          form.change('escort', data);
          form.change('isInitDuoProfile', true);
          initialValues.meetingType === 'duo' && form.change('tempInitEscort', initialValues.escort);
        } else {
          form.change('profile', data);
          form.change('rate', {
            label: `£${rateValue}`,
            value: rateValue,
            type: 'D'
          });
          form.change('tempInitSoloProfile', initialValues.profile);
          form.change('services', data.id === initialValues.profileId ? initialValues.services : data.services);
          form.change('profileId', data.id);
          form.change('bookingDuration', {
            label: booking.duration,
            value: rateValue
          });
        }
        setDuoFieldOpen(true);

        setProfile(data);
      }

      dispatch(closeModal(MODAL_TYPES.searchList));
    };

    subMenuItemsActions[fieldType]();
  };

  const handleDeleteBooking = (booking) => {
    const msgText = props.currentUser.role === 'admin'
        ? 'The booking will be permanently deleted'
        : 'Delete Booking?';

    if (window.confirm(msgText)) {
      dispatch(setUpdateBookingsPending(true));

      const bookingIdsForDeleting = [...new Set([booking.id, ...booking.groupGirls.map(({ bookingId }) => bookingId)])];

      const responses = bookingIdsForDeleting.map(id => () => API.deleteBookingV2(id, props.canPermanentlyDeleteBooking));

      // Promise.all(responses)
      //   .then(() => {
      //     props.closeModal();
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
      //   .finally(() => {
      //     dispatch(setUpdateBookingsPending(false));
      //   })

      responses[0]()
        .then(() => {
          if (!responses[1]) props.closeModal();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          if (!responses[1]) dispatch(setUpdateBookingsPending(false));
        })
      
      setTimeout(() => {
        if (responses[1]) {
          responses[1]()
            .then(() => {
              props.closeModal();
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              dispatch(setUpdateBookingsPending(false));
            })
        }
      }, 1000)
    }
  }

  const updateAttachments = useCallback((attachment, form, values) => {
    const newValues = {
      ...values,
      notesAttachments: attachment[0]
    }
    const clearAttachments = () => form.change('notesAttachments', null)
    const saveUUID = (uuid) => form.change('address.uuid', uuid)

    handleUpdateBooking({ values: newValues, saveUUID, clearAttachments });
  }, [booking])

  const deleteAttachemnts = useCallback((i) => {
    props.deleteAttachment(booking.id, i);
  }, []);

  if (isLoadingBooking) {
    return <Spinner spinnerSize={30} className='booking-edit-form__spinner-updating' />;
  }

  return (
    <Form
      onSubmit={handleUpdateBooking}
      validate={validateForm}
      mutators={arrayMutators}
      initialValues={initialValues}
      render={({ handleSubmit, values, form }) => {

        const isDuo = !!values.escort;
        const saveUUID = (uuid) => form.change('address.uuid', uuid);

        const escortNames = [values.profile?.name, values.escort?.name].filter(Boolean).join(', ');
        const escortIds = getSelectedProfilesList(values).map(profile => profile.id);

        const isFormEmpty = !values.profile && !values.escort;

        const bookingAddress = values.type === 'incall' ? incallAddress.locationName : values.address?.locationName;

        const handleOpenMap = () => {
          if (values.type === 'incall') {
            openModal(MODAL_TYPES.girlsMap, { mapProps: { profile: values.profile }})
          } else {
            const clientLocation = {
              lat: values.address?.latitude,
              lng: values.address?.longitude,
              address: values.address?.locationName,
            }

            openModal(MODAL_TYPES.girlsMap, { mapProps: { clientLocation }})
          }
        }

        return (
          <form onSubmit={handleSubmit} className='booking-edit-form'>
            <BookingEditAutoSave
              isNotFull={props.updatedBooking?.isNotFull}
              saveBooking={handleUpdateBooking}
              prevFormState={prevFormState}
              saveUUID={saveUUID}
            />

            <div className='booking-edit-form__header'>
              <div className='booking-edit-form__btns-wrap'>
                {getSelectedProfilesList(values).length < 2 &&
                  <Field
                    isBooking
                    name="escort"
                    fieldType='diva'
                    isDisabled={booking.is_deleted || isFormEmpty}
                    bookingDivaId={[values.profile?.id]}
                    component={FeedbackContactField}
                    setDuoFieldOpen={setDuoFieldOpen}
                    isFormEmpty={isFormEmpty}
                    setMeetingType={(girl) => {
                      form.change('isInitDuoProfile', true);
                      setTempProfile(girl);
                    }}
                  />
                }

                {isLoading && <Spinner spinnerSize={30} className='booking-edit-form__spinner-updating' />}
              </div>

              <div className='booking-edit-form__btns-wrap'>
                <Button
                  className="booking-edit-form__btn--delete-booking"
                  onClick={() => handleDeleteBooking(booking)}
                  disabled={booking.is_deleted && !props.canPermanentlyDeleteBooking}
                  icon={<ICONS.trash />}
                >
                  Delete Booking
                </Button>
                
                <Field name="bookingStatus">
                  {({ input: { value } }) => (
                    <BookingFormStatusField
                      form={form}
                      status={value}
                      isDisabled={booking.is_deleted || isFormEmpty}
                      values={values}
                    />
                  )}
                </Field>
              </div>

              <button
                type='button'
                onClick={() => props.closeModal()}
                className={classModifier('booking-edit-form__btn', 'cancel')}
              >
                <ICONS.close className={classModifier('booking-edit-form__btn-icon', 'cancel')} />
                (Esc)
              </button>
            </div>

            {isDuoFieldOpen &&
              <DuoBookingForm
                values={values}
                formChange={form.change}
                tempEscort={tempProfile}
                isOverlaps={overlaps.isOverlaps}
                setDuoFieldOpen={setDuoFieldOpen}
                updateBooking={handleUpdateBooking}
                resetTempProfile={() => setTempProfile(null)}
              >
                <BookingEditFieldsItem
                  fieldName="profile"
                  profile={values.profile}
                />
                {(tempProfile || values.escort) &&
                  <BookingEditFieldsItem
                    fieldName="escort"
                    profile={tempProfile || values.escort}
                  />
                }
              </DuoBookingForm>
            }

            <div className='booking-edit-form__main'>
              <div className='booking-edit-form__content-wrapper'>
                {isDuo && <span className='booking-edit-form__duo-logo'>DUO</span>}

                <div className='booking-edit-form__operator'>
                  <span className='booking-edit-form__operator-title'>Operator:</span>
                  <span className='booking-edit-form__operator-name'>{getOperatorName(values.operatorId)}</span>

                  <Field name="operatorId">
                    {() => (
                      <button
                        type='button'
                        disabled={booking.is_deleted || isFormEmpty}
                        onClick={() => handleOpenItemsModal(form, 'operator', [values.profileId])}
                        className={classModifier('booking-edit-form__btn', 'operator')}
                      >
                        <ICONS.chevron className={classModifier('booking-edit-form__btn-icon', 'operator')} />
                      </button>
                    )}
                  </Field>
                </div>

                <div className={classModifier('booking-edit-form__fields-wrapper', 'contacts')}>
                  <div className='booking-edit-form__client-info-block'>
                    <CallerInfo callerId={booking.callerId || booking.caller_id} />
                  </div>

                  <div className={classModifier('booking-edit-form__selected-profiles',
                    !getSelectedProfilesList(values).length && 'is-no-profiles')}
                  >
                    {isLoadingProfiles &&
                      <div className='booking-edit-form__selected-profiles-spinner-wrapper'>
                        <Spinner spinnerSize={50} />
                      </div>
                    }

                    {/* {!!getSelectedProfilesList(values).length
                      && getSelectedProfilesList(values).map((selectedProfile, idx, profiles) => (
                        <BookingSelectedProfile
                          idx={idx}
                          isDisabled={booking.is_deleted}
                          key={selectedProfile.id}
                          profile={selectedProfile}
                          notForIds={notForIds}
                          deleteBooking={(idx, profileType) => handleDeleteBooking(idx, profileType, form.change, selectedProfile)}
                          setProfile={() => handleOpenItemsModal(
                            form,
                            'diva',
                            profiles.map(p => p.id),
                            selectedProfile.id === values.escort?.id
                          )}
                        />
                      ))
                    } */}
                    <BookingSelectedProfiles
                      values={values}
                      notForIds={notForIds}
                      changeForm={form.change}
                      handleUpdateBooking={handleUpdateBooking}
                      disabled={booking.is_deleted}
                    />
                    {overlaps.isOverlaps && (
                      <OverlappedBookingsMsg
                        conflictBookings={overlaps.conflictBookings}
                        values={values}
                        submitForm={handleUpdateBooking}
                      />
                    )}

                    {(profile?.isNotExist || duoProfile?.isNotExist) && (
                      <div className='overlapped-booking-msg__warning-msg-wrap'>
                        <div className='overlapped-booking-msg__warning-msg'>
                          No girl's profile was found. The information is displayed from the contact assigned to her. Changing the form is limited!
                        </div>

                        <div className='overlapped-booking-msg__warning-msg-icon-wrap'>
                          <ICONS.warn className='overlapped-booking-msg__warning-msg-icon'/>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  onDoubleClick={() => setDuoFieldOpen(true)}
                  className={classModifier('booking-edit-form__fields-wrapper', 'booking-details')}
                >
                  <button
                    type='button'
                    className={classModifier('booking-edit-form__btn', 'edit-details')}
                    onClick={() => setDuoFieldOpen(true)}
                    disabled={booking.is_deleted || (profile?.isNotExist || duoProfile?.isNotExist) || isFormEmpty}
                  >
                    <ICONS.pencil
                      className={classModifier('booking-edit-form__btn-icon', 'edit-details')}/>
                  </button>

                  <div className='booking-edit-form__booking-details-group'>
                    <h4 className='booking-edit-form__booking-details-title'>Booking details:</h4>

                    {values.date &&
                      <DateTime
                        date={values.date}
                        config={BOOKING_DETAILS_DATE_CONFIG}
                      />
                    }

                    {values.meetingType === 'solo' && renderTypeDurationDetails(values)}
                  </div>

                  {/* SOLO DETAILS */}

                  {isDuo && <div className='booking-edit-form__booking-details-group'>
                    <h4 className={classModifier('booking-edit-form__booking-details-title',
                      ['diva-name', !!values.profile?.agent_id && 'has-agent']
                    )}
                    >
                      {values.profile?.name}:
                      {!!values.profile?.agent_id && <ICONS.a className='booking-edit-form__agent-icon' />}
                    </h4>

                    {renderTypeDurationDetails(values)}
                  </div>
                  }

                  <div className='booking-edit-form__booking-details-group'>
                    <h4 className='booking-edit-form__booking-details-title'>Services:</h4>
                    {renderCheckedServices(values.services || [])}
                  </div>

                  <div className='booking-edit-form__booking-details-group-block'>
                    <div className={classModifier('booking-edit-form__booking-details-group', 'rate')}>
                      <h4 className='booking-edit-form__booking-details-title'>
                        Rate{isDuo && ` ${values.profile?.name}`}:
                      </h4>
                      <span>{values.rate?.value && `£ ${values.rate.value}`}</span>
                    </div>

                    <div className={classModifier('booking-edit-form__booking-details-group', 'discount')}>
                      <h4 className='booking-edit-form__booking-details-title'>Discount:</h4>
                      {values.discount?.value && <span>£ {values.discount.value}</span>}
                    </div>
                  </div>

                  <div className='booking-edit-form__booking-details-group-block'>
                    <div className={classModifier('booking-edit-form__booking-details-group', 'extras')}>
                      <h4 className='booking-edit-form__booking-details-title'>Extras:</h4>
                      {renderPaidServices(values.services || [])}
                    </div>

                    <div className='booking-edit-form__booking-details-group'>
                      <h4 className='booking-edit-form__booking-details-title'>Taxi:</h4>
                      {values.taxi?.value && <span>£ {values.taxi.value}</span>}
                    </div>
                  </div>

                  {values.taxiNote &&
                    <div className='booking-edit-form__booking-details-group-block'>
                      <div
                        title={values.taxiNote}
                        className={classModifier('booking-edit-form__booking-details-group', 'taxi-note')}
                      >
                        <h4 className='booking-edit-form__booking-details-title'>Taxi note:</h4>
                        {values.taxiNote && <span>{values.taxiNote}</span>}
                      </div>
                    </div>
                  }

                  {/* DUO DETAILS */}

                  {isDuo &&
                    <>
                      <div className='booking-edit-form__booking-details-group'>
                        <h4 className={classModifier('booking-edit-form__booking-details-title',
                          ['diva-name', !!values.escort?.agent_id && 'has-agent']
                        )}
                        >
                          {values.escort?.name}:
                          {!!values.escort?.agent_id && <ICONS.a className='booking-edit-form__agent-icon' />}
                        </h4>

                        {values.escort?.type && <span className='booking-edit-form__booking-details-type'> {values.escort?.type} / </span>}
                        {values.escort?.bookingDuration && <span>{values.escort?.bookingDuration.label} / </span>}
                        {values.escort?.bookingDuration && <span>£{values.escort?.bookingDuration.value}</span>}
                      </div>

                      <div className='booking-edit-form__booking-details-group'>
                        <h4 className='booking-edit-form__booking-details-title'>Services:</h4>
                        {renderCheckedServices(values.escort?.services || [])}
                      </div>

                      <div className='booking-edit-form__booking-details-group-block'>
                        <div className={classModifier('booking-edit-form__booking-details-group', 'rate')}>
                          <h4 className='booking-edit-form__booking-details-title'>Rate {values.escort?.name}:</h4>
                          <span>£ {values.escort.rate?.value}</span>
                        </div>

                        <div className={classModifier('booking-edit-form__booking-details-group', 'discount')}>
                          <h4 className='booking-edit-form__booking-details-title'>Discount:</h4>
                          {values.escort?.discount?.value && <span>£ {values.escort?.discount.value}</span>}
                        </div>
                      </div>

                      <div className='booking-edit-form__booking-details-group-block'>
                        <div className={classModifier('booking-edit-form__booking-details-group', 'extras')}>
                          <h4 className='booking-edit-form__booking-details-title'>Extras:</h4>
                          {renderPaidServices(values.escort?.services || [])}
                        </div>

                        <div className='booking-edit-form__booking-details-group'>
                          <h4 className='booking-edit-form__booking-details-title'>Taxi:</h4>
                          {values.escort?.taxi?.value && <span>£ {values.escort?.taxi.value}</span>}
                        </div>
                      </div>

                      {values.escort?.taxiNote &&
                        <div className='booking-edit-form__booking-details-group-block'>
                          <div
                            title={values.escort?.taxiNote}
                            className={classModifier('booking-edit-form__booking-details-group', 'taxi-note')}
                          >
                            <h4 className='booking-edit-form__booking-details-title'>Taxi note:</h4>
                            {values.escort?.taxiNote && <span>{values.escort?.taxiNote}</span>}
                          </div>
                        </div>
                      }
                    </>
                  }

                  <div className='booking-edit-form__booking-details-group'>
                    <h4 className='booking-edit-form__booking-details-title'>Total:</h4>
                    {values.rate?.value
                      // ? <span className='booking-form__booking-total-rate'>£{getTotalRate(values)}</span> 
                      && <span className='booking-form__booking-total-rate'>{renderTotalRate(values)}</span>
                    }
                  </div>
                </div>

                <div
                  onDoubleClick={() => setIsLocationEditorOpen(true)}
                  className={classModifier('booking-edit-form__fields-wrapper', 'booking-address')}
                >
                  <button
                    type='button'
                    onClick={() => setIsLocationEditorOpen(true)}
                    className={classModifier('booking-edit-form__btn', 'edit-special-request')}
                    disabled={booking.is_deleted || (profile?.isNotExist || duoProfile?.isNotExist) || isFormEmpty}
                  >
                    <ICONS.pencil className={classModifier('booking-edit-form__btn-icon', 'edit-special-request')} />
                  </button>

                  {isLocationEditorOpen &&
                    <BookingLocationEditor
                      bookingId={booking.id}
                      type={values.type}
                      address={values.type === 'incall' ? incallAddress : values.address || incallAddress}
                      isLocationEditorOpen={isLocationEditorOpen}
                      setIsLocationEditorOpen={setIsLocationEditorOpen}
                      callerId={booking.callerId}
                    />
                  }

                  <div className="booking-edit-form__booking-details-group">
                    <h4 className='booking-edit-form__booking-details-title'>Booking address:</h4>
                    <div className='booking-edit-form__booking-details-group'>
                      <span>{bookingAddress}</span>
                    </div>
                  </div>

                  {!isFormEmpty && bookingAddress && (
                    <button
                      type="button"
                      className="booking-edit-form__open-map-btn"
                      onClick={handleOpenMap}
                    >
                      <ICONS.mapFill />
                      Open Map
                    </button>
                  )}
                </div>

                <div
                  onDoubleClick={() => !booking.is_deleted && !isFormEmpty && setIsRequestFieldOpen(true)}
                  className={classModifier('booking-edit-form__fields-wrapper', 'special-request')}
                >
                  <BookingSpecialRequestField
                    isRequestFieldOpen={isRequestFieldOpen}
                    setIsRequestFieldOpen={setIsRequestFieldOpen}
                    isEditBooking
                    bookingId={booking.id}
                    getBookingLog={getBookingLog}
                    callerId={booking.callerId}
                    isDisabled={booking.is_deleted || isFormEmpty}
                    isFormEmpty={isFormEmpty}
                  />

                  {values.specialRequestMsgs?.length
                    ? values.specialRequestMsgs?.map((msg, idx) => {
                      return (
                        <div
                          key={idx}
                          className="booking-edit-form__booking-details-group"
                        >
                          <h4 className="booking-edit-form__booking-details-title">
                            Special request:
                          </h4>
                          <span className="booking-edit-form__booking-request-msg">
                            {msg.text}
                          </span>
                        </div>
                      );
                    })
                    : (
                      <div className="booking-edit-form__booking-details-group">
                        <h4 className="booking-edit-form__booking-details-title">
                          Special request:
                        </h4>
                        {Boolean(values.requestText) && (
                          <span className="booking-edit-form__booking-request-msg">
                            {values.requestText}
                          </span>
                        )}
                      </div>
                    )
                  }
                </div>

                <div
                  onDoubleClick={() => !booking.is_deleted && !isFormEmpty && setIsRequestMessageFieldOpen(true)}
                  className={classModifier('booking-edit-form__fields-wrapper', 'messages')}
                >
                  <BookingSpecialRequestMessages
                    isRequestMessageFieldOpen={isRequestMessageFieldOpen}
                    setIsRequestMessageFieldOpen={setIsRequestMessageFieldOpen}
                    isEditBooking
                    callerId={booking.callerId}
                    bookingId={booking?.id}
                    updateBookingsList={updateBookingsList}
                    getBookingLog={getBookingLog}
                    isDisabled={booking.is_deleted || isFormEmpty || (profile?.isNotExist || duoProfile?.isNotExist)}
                    isFormEmpty={isFormEmpty}
                  />

                  {values.scheduleMessages?.length
                    ? values.scheduleMessages.map((msg, idx) => {
                      return (
                        <div key={idx} className={classModifier('booking-edit-form__booking-details-group', 'request-msg')}>
                          <div className='booking-edit-form__booking-details-group'>
                            <h4 className='booking-edit-form__booking-details-title'>Message to client:</h4>
                            {msg.date &&
                              <DateTime
                                date={msg.date}
                                config={BOOKING_MESSAGES_DATE_CONFIG}
                              />
                            }
                          </div>
                          <span className='booking-edit-form__booking-request-msg'>{msg.body}</span>
                        </div>
                      )
                    })
                    : <div className={classModifier('booking-edit-form__booking-details-group', 'request-msg')}>
                      <div className='booking-edit-form__booking-details-group'>
                        <h4 className='booking-edit-form__booking-details-title'>Message to client:</h4>
                      </div>
                    </div>
                  }
                </div>

                {!isFormEmpty && (
                  <div className={classModifier('booking-edit-form__fields-wrapper', 'prebookings')}>
                    <PrebookingsList
                      userHour12={userHour12}
                      escortNames={escortNames}
                      userTimezone={userTimezone}
                      clientName={booking.caller?.fn || booking.caller_fn}
                      callerId={booking.callerId || booking.client_caller_id}
                      escortIds={escortIds}
                      exceptUuid={booking.uuid_group}
                    />
                  </div>
                )}

              </div>

              <div className={classModifier('booking-edit-form__info-wrapper', !isDuo && 'for-solo')}>
                {!!values.requirements?.length &&
                  <>
                    {isDuo &&
                      <h4
                        className={classModifier('booking-edit-form__requirements-title', values.profile?.agent_id && 'has-agent')}
                      >
                        {values.profile?.name}
                        {values.profile?.agent_id && <ICONS.a className='booking-edit-form__agent-icon' />}
                      </h4>
                    }
                    <FieldArray
                      name='requirements'
                      component={BookingRequirements}
                      actualType={values.type}
                      isDisabled={isFormEmpty}
                    />
                  </>
                }

                {isDuo && !!values.duoRequirements?.length &&
                  <>
                    <h4
                      className={classModifier('booking-edit-form__requirements-title', values.escort?.agent_id && 'has-agent')}
                    >
                      {values.escort?.name}
                      {values.escort?.agent_id && <ICONS.a className='booking-edit-form__agent-icon' />}
                    </h4>
                    <FieldArray
                      name='duoRequirements'
                      component={BookingRequirements}
                      actualType={values.type}
                      isDisabled={isFormEmpty}
                    />
                  </>
                }

                <BookingNotes
                  attachments={attachments}
                  updateAttachments={(attachment) => updateAttachments(attachment, form, values)}
                  deleteAttachemnts={(i) => deleteAttachemnts(i)}
                  isDisabled={booking.is_deleted || isFormEmpty}
                />

                <BookingLog
                  bookingLog={bookingLog}
                  isDuoProfileExisted={!!values.escort}
                />

                {/* <div className={classModifier('booking-edit-form__booking-details-wrapper', 'messages')}>
                  <BookingSpecialRequestMessages isEditBooking />

                  {values.requestList?.map((msg, idx) => {
                    return (
                      <div key={idx} className={classModifier('booking-edit-form__booking-details-group', 'request-msg')}>
                        <div className='booking-edit-form__booking-details-group'>
                          <h4 className='booking-edit-form__booking-details-title'>Message to client:</h4>
                          {msg.date && renderFormattedMsgRequestDate(msg.date)}
                        </div>
                        <span className='booking-edit-form__booking-request-msg'>{msg.text}</span>
                      </div>
                    )
                  })}
                </div> */}
              </div>
            </div>
          </form>
        )
      }}
    />
  )
}

const validateForm = values => {
  const errors = {};

  setRequiredIfEmpty('type');
  setRequiredIfEmpty('bookingDuration');
  setRequiredIfEmpty('date');
  return errors;

  function setRequiredIfEmpty(fieldName) {
    if (!values[fieldName]) {
      errors[fieldName] = 'Required';
    }
  }
}

const mSTP = (state, ownProps) => ({
  bookingStateProfiles: selectBookedProfilesListById(state, ownProps.profileId, ownProps.booking),
  activeSessionContact: ownProps.activeContact ?? selectActiveSessionContact(state),
  activeSessionId: selectActiveSessionId(state),
  userHour12: state.user.hour12,
  userTimezone: selectUserTimezone(state),
  operators: state.operators.entities,
  currentUser: state.user,
  updatedBooking: state.bookings.entities[ownProps.booking.id],
  requirements: selectAllRequirements(state),
  bookingLog: state.bookings.log,
  canPermanentlyDeleteBooking: state.user.access.canPermanentlyDeleteBooking,
});

const mDTP = {
  openModal,
  updateBooking,
  deleteBooking,
  getActiveDivaGirls,
  updateBookingByValues,
  deleteAttachment,
  setBookingLog,
  clearBookingLog,
  getContactsById
};

export default connect(mSTP, mDTP)(BookingEditForm);
